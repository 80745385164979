import * as React from 'react'
import * as css from './GeneralForm.module.css'
import { Input, Select, Textarea } from 'components/form/Inputs'
import MailchimpStatus from 'components/common/MailchimpStatus'

export default function GeneralForm({ subscribe, status, message }) {
	const fname = React.useRef(null)
	const lname = React.useRef(null)
	const email = React.useRef(null)
	const tel = React.useRef(null)
	const servicesNeeded = React.useRef(null)
	const comments = React.useRef(null)

	const submit = () =>
		fname.current.value &&
		lname.current.value &&
		email.current.validity.valid &&
		subscribe({
			FNAME: fname.current.value,
			LNAME: lname.current.value,
			EMAIL: email.current.value,
			PHONE: tel.current.value,
			MMERGE5: servicesNeeded.current.value,
			MMERGE3: comments.current.value,
		})

	return (
		<>
			<div>
				<div className={css.inputs}>
					<Input ref={fname} label="First Name" required />
					<Input ref={lname} label="Last Name" required />
					<Input ref={email} label="Email" type="email" required />
					<Input ref={tel} label="Phone Number" />

					<Select
						ref={servicesNeeded}
						className={css.stretch}
						label="Services Needed"
						optional
					>
						<option value="" default selected>
							{''}
						</option>
						<option value="Conventional Fuel">Conventional Fuel</option>
						<option value="Alternative Energy">Alternative Energy</option>
						<option value="Other">Other</option>
					</Select>

					<Textarea
						ref={comments}
						className={css.stretch}
						label="Comments"
						optional
					></Textarea>
				</div>

				<div className={css.bottom}>
					<button className="action lg" onClick={submit}>
						Get started
						<span className="arrow">➞</span>
					</button>
				</div>
			</div>

			<MailchimpStatus
				status={status}
				message={message}
				redirectUrl="/contact/thank-you"
			/>
		</>
	)
}
